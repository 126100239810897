<template>
  <div>
    <v-row
      v-if="listProducts.length && !loading"
      justify-xl="center"
    >
      <v-col v-for="item in listProducts" :key="item.uuid" cols="12" sm="6" md="4" xl="2">
        <item-card
          :item="item"
          :loading="item.uuid === loadingItemCard.uuid && loadingItemCard.loading"
        >
        </item-card>
      </v-col>
    </v-row>
    <v-row v-else-if="!listProducts.length && !loading">
      <v-col cols="12" class="text-center">
        {{ $t("table.noData") }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ItemCard from "./ItemCard.vue";

export default {
  props: {
    listProducts: {
      require: true
    },
    loading: {
      require: true
    },
    loadingItemCard: {
      require: true
    }
  },
  components: {
    ItemCard
  }
};
</script>
<style lang="scss" >

</style>
