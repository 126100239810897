<template>
  <div class="footer__wrapper">
    <v-container class="text-center footer__content">
      <div class="mr-2">
        <span class=" text-caption">Powered by</span>
      </div>
      <div class="img__wrapper">
        <img :src="require('@/assets/images/logo-ns-figure-dark.png')" alt="logo" @click="onClickImg"/>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  methods:{
    onClickImg(){
      window.open("http://newsend.ua", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  &__wrapper {
width: 100%;
    // position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: #f4f4f4;
    z-index: 4;
    box-shadow: 0 1px 2px 2px rgba(#e4e4e4, 1);
    .img {
      &__wrapper {
        cursor: pointer;
        max-width: 76px;
        height: 20px;
        width:100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  &__content {
    display: flex;
    align-items: center;
  }
}
</style>
