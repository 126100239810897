<template>
  <div>
    <div v-for="(product, index) in products" :key="product.id">
      <v-row justify="space-between" align="center" class="px-2 my-1">
        <v-col cols="auto" class="position-relative">
          <div class="sale-btn__wrapper" v-if="Number(product.discount) > 0">
            <v-btn color="error" class="white--text" fab :ripple="false" dense x-small>
              <!-- <div class="font-weight-bold" style="transform:rotate(-25deg)">
            -25%
          </div> -->
              <v-icon>mdi-sale</v-icon>
            </v-btn>
          </div>
          <img
            v-if="!product.path_img"
            :src="require('@/assets/images/supply-item.png')"
            class="image-placeholder"
          />
          <v-img v-else :src="product.path_img" class="image ">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
                style="min-height:60px"
              >
                <v-progress-circular
                  indeterminate
                  :size="20"
                  :width="2"
                  color="green lighten-1"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col cols="8" md="6" sm="6" xs="8">
          <div class="text-truncate d-flex">
            <div>
              {{ product.name || "--" }}
            </div>
            <div v-if="product.option_name">({{ product.option_name || "--" }})</div>
          </div>
          <div class="text-caption grey--text text-truncate">
            {{ product.description || "--" }}
          </div>
        </v-col>
        <v-col class="d-flex justify-content-end" cols="12" sm="auto" xs="12">
          <ns-counter
            v-model="product.amount"
            :min="1"
            :max="999"
            @change="onChangeAmount(product)"
          />
        </v-col>
        <v-col cols="6" md="2" sm="2">
          <span class="price font-weight-medium">
            {{ getProductPrice(product) }}{{ $t("paymentPlan.uah") }}
          </span>
        </v-col>
        <v-col cols="6" md="2" sm="2" class="d-flex justify-content-end">
          <v-btn icon plain @click="onDeleteCartItem(product)" :disabled="onLoadingDeleteItem">
            <v-icon color="error">
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider v-show="index + 1 != products.length" class="mx-2" />
    </div>
  </div>
</template>

<script>
import imgDialog from "@/components/products/imgDialog.vue";
import EventBus from "@/events/EventBus";

export default {
  name: "CartItems",
  props: {
    products: {
      require: true
    },
    onLoadingDeleteItem: {
      require: true
    }
  },
  data: () => ({
    isImgLoaded: false,
    imgDialog: false,
    imgpath: ""
  }),
  methods: {
    getProductPrice({ price, amount, discount }) {
      const discountToNumber = Number(discount);
      const priceToNumber = Number(price);

      if (discountToNumber > 0) {
        return Math.floor(priceToNumber * ((100 - discountToNumber) / 100)) * amount;
      } else {
        return (priceToNumber * amount).toFixed(2);
      }
    },
    onChangeAmount(data) {
      EventBus.$emit("on-modified-item-cart", data);
    },
    onDeleteCartItem({ uuid }) {
      EventBus.$emit("on-delete-from-cart", uuid);
    }
  },

  components: {
    imgDialog
  }
};
</script>

<style lang="scss" scoped>
.sale-btn {
  &__wrapper {
    position: absolute;
    left: -3%;
    top: -3%;
    z-index: 4;
  }
}
</style>
